<template>
  <div class="box mt-4">
    <div class="box-header">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <b>{{ identifier }}</b
          >&nbsp; {{ title || '' }}
          <div
            v-if="tooltip"
            class="text-gray text-medium pointer ml-3"
            v-tooltip="{
              content: tooltip,
            }"
          >
            <u>ข้อมูลเพิ่มเติม</u>
          </div>
        </div>
        <div
          v-if="ready && !error"
          class="d-flex align-items-center justify-content-end ml-3"
        >
          <a
            class="btn-red mr-3"
            :href="chartUrl"
            target="_blank"
            v-show="show"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
              <path d="M4 18h14V4H4v14zM6 6h10v10H6V6z" />
              <path
                d="M2 16H0v6h6v-2H2zM2 2h4V0H0v6h2zM20 20h-4v2h6v-6h-2zM16 0v2h4v4h2V0z"
              />
            </svg>
          </a>
          <button
            style="min-width: 122px"
            type="button"
            class="btn btn-outline-secondary p-2 text-medium d-flex flex-grow-1"
            v-if="show"
            v-promise-btn
            @click="requestChartImageUrl"
          >
            <span class="flex-shrink-1">สร้างลิงค์รูปภาพ</span>
          </button>
          <collapse-button v-model="show"></collapse-button>
        </div>
        <vue-element-loading
          v-else-if="!ready && !error"
          :active="!ready"
          spinner="spinner"
          color="#ED110E"
          class="position-relative mr-3 mb-2"
          background-color="transparent"
        />
        <div v-else class="text-danger">การเรียกข้อมูลล้มเหลว</div>
      </div>
      <div class="mt-2 d-flex" v-if="imageUrl">
        <input
          type="text"
          disabled
          class="
            border
            rounded
            text-light-ipepp
            bg-lighter-blue
            flex-grow-1
            px-2
            text-small
          "
          :value="imageUrl"
        />
        <div>
          <button
            class="btn btn-light p-1 ml-1"
            v-clipboard:copy="imageUrl"
            v-clipboard:success="copied"
          >
            คัดลอก
          </button>
        </div>
      </div>
    </div>
    <transition name="fadeHeight">
      <div v-if="show">
        <div class="box-body d-flex flex-column">
          <iframe
            :src="chartUrl"
            :height="height + 'px'"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            vspace="0"
            hspace="0"
            seamless="seamless"
          >
          </iframe>
          <div
            class="mt-4 mb-3 text-medium text-secondary text-center"
            v-if="helperText"
          >
            <div>{{ helperText }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { head, drop } from 'lodash';
import CollapseButton from '@/components/CollapseButton.vue';

export default {
  name: 'MondasChartBox',

  components: {
    CollapseButton,
  },

  props: {
    chartUrl: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 600,
    },
    tooltip: {
      type: String,
      default: null,
    },
    helperText: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      imageUrl: null,
      show: true,
      isChart: true,
      ready: true,
      error: false,
    };
  },

  mounted() {},

  computed: {
    ...mapState(['locales']),
    ...mapGetters(['currentLocaleDisplay']),
    chartImageUrl() {
      return ``;
    },
  },

  methods: {
    ...mapActions('peno', ['fetchMondasChartImageUrl']),
    copied() {
      this.$toasted.global.copied();
    },
    requestChartImageUrl() {
      if (this.imageUrl) return;
      return this.fetchMondasChartImageUrl(this.chartUrl)
        .then((data) => {
          this.imageUrl = data;
        })
        .catch((err) => {
          console.error(err);
          this.$toasted.global.display_error({
            message: err,
          });
        });
    },
  },
};
</script>
