<template>
  <div>
    <mondas-chart-box
      :chartUrl="chartUrl"
      :identifier="chartIdentifier(1)"
      title="แผนผังแสดงการไหลของข้อมูลพลังงาน"
      :height="800"
    />
  </div>
</template>

<script>
import MondasChartBox from '@/components/MondasChartBox.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { kebabCase } from 'lodash';

export default {
  name: 'energy-diagram',

  components: {
    MondasChartBox,
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      topicNumber: '2.1',
    };
  },

  created() {},

  computed: {
    ...mapGetters('peno', ['currentProvince']),
    ...mapGetters(['latestNationalPlan']),
    chartUrl() {
      const provinceName = kebabCase(this.currentProvince.name_en);
      return `https://pemt-api.mondas.io/sankey/current?province=${provinceName}&type=structure_demand_powerplant`;
    },
  },

  methods: {
    chartIdentifier(number) {
      return this.topicNumber + '.' + number;
    },
  },
};
</script>

<style scoped></style>
